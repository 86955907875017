<template>
  <div>
    <div class="cartOrder" v-if="!order.placed">
      <h3 class="retailerName">
        {{ retailerName }}
      </h3>
      <cart-item
        class="uk-margin"
        v-for="item in order.products"
        :key="item.id"
        :item="item"
      ></cart-item>

      <div class="orderSummary">
        <hr class="divider uk-width-2-3" />
        <h3 class="total">
          <span class="label">suma:</span>
          {{ total }} zł
        </h3>
        <button v-if="!order.placed" class="reviewOrder" @click="reviewOrder">
          Złóż zamówienie
        </button>
        <button v-else disabled class="reviewOrder uk-secondary">
          Wysłane
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CartItem from './CartItem'

export default {
  name: 'CartOrder',
  props: ['order'],
  components: {
    CartItem
  },
  data() {
    return {
      actionId: this.order.actionId,
      current: {}
    }
  },
  computed: {
    ...mapGetters(['retailerNameByActionId']),
    retailerName() {
      return this.retailerNameByActionId(this.order.actionId)
    },
    id() {
      return this.order.id
    },
    total() {
      return this.order.products
        .reduce((a, b) => a + b.quantity * b.price, 0)
        .toFixed(2)
    }
  },
  methods: {
    reviewOrder() {
      this.$router
        .push({ name: 'orderPreview', params: { id: this.id } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';
.retailerName {
  @extend .uk-margin-remove;
}
.orderSummary {
  @extend .uk-text-center;
  @extend .uk-margin-large-bottom;
  .divider {
    @extend .uk-margin-auto;
    @extend .uk-margin-small-top;
  }
  .total {
    @extend .uk-margin-remove;
    @extend .uk-text-center;
    .label {
      @extend .uk-text-muted;
      @extend .uk-text-small;
    }
  }
  button.reviewOrder {
    @extend .uk-button;
    @extend .uk-button-primary;
    @extend .uk-margin-small;
    @extend .uk-border-rounded;
    text-transform: none;
  }
}
</style>
