<template>
  <div>
    <div class="uk-position-relative">
      <font-awesome-icon
        icon="chevron-right"
        class="uk-position-center-right uk-text-light"
        size="2x"
        @click="changeQuantity"
      />
      <h4 class="uk-margin-remove">
        {{ item.name }}
      </h4>
      <div class="uk-text-muted">
        {{ item.float ? parseFloat(item.quantity).toFixed(2) : item.quantity }}
        x {{ item.price.toFixed(2) }}
        <span class="uk-text-small">{{ item.unit }}</span> ({{
          (item.quantity * item.price).toFixed(2)
        }}
        zł)
      </div>
    </div>
    <quantity-modal v-model="item"></quantity-modal>
  </div>
</template>

<script>
import { modal } from 'uikit'

import QuantityModal from './QuantityModal'

export default {
  name: 'CartItem',
  components: {
    QuantityModal
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    changeQuantity() {
      modal(document.getElementById('modal-quantity-' + this.item.id)).show()
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-card-body {
  padding-right: 2em;
}
h4 {
  padding-right: 2em;
}
</style>
