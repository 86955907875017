<template>
  <div class="uk-container">
    <div v-if="itemsInCart === 0">
      <h4>Nie masz nic w koszyku</h4>
    </div>
    <cart-order
      v-else
      v-for="order in getCart"
      :key="order.actionId"
      :order="order"
    ></cart-order>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CartOrder from '../components/Cart/CartOrder'

export default {
  name: 'Cart',
  computed: {
    ...mapGetters(['getCart', 'itemsInCart'])
  },
  components: {
    CartOrder
  },
  data: () => {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';
</style>
