<template>
  <div :id="'modal-quantity-' + id" uk-modal>
    <div
      class="uk-modal-dialog uk-modal-body uk-text-center uk-margin-auto-vertical"
    >
      <h4>{{ value.name }}</h4>
      <div>
        <div class="reduce" v-if="!float">
          <font-awesome-icon
            icon="minus"
            @click="quantity > 1 ? --quantity : ''"
          />
        </div>
        <input
          :class="[
            'uk-input',
            'uk-text-center',
            'uk-form-small',
            float ? 'uk-form-width-small' : 'uk-form-width-xsmall'
          ]"
          type="number"
          :readonly="!float"
          v-model="quantity"
        />
        <div class="add" v-if="!float">
          <font-awesome-icon icon="plus" @click="quantity++" />
        </div>
      </div>
      <div class="uk-margin" v-if="remove">
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          @click="remove = false"
        >
          Anuluj
        </button>
        <button
          class="uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
          @click="removeProduct"
        >
          Potwierdź
        </button>
      </div>
      <div class="uk-margin" v-else>
        <button
          class="uk-button uk-button-primary uk-border-rounded uk-modal-close"
        >
          Potwierdź
        </button>
        <button
          class="uk-button uk-button-secondary uk-border-rounded uk-margin-small-left"
          @click="remove = true"
        >
          Usuń
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from 'uikit'

export default {
  name: 'QuantityModal',
  props: {
    value: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: false
    }
  },
  created() {
    const m = document.getElementById('modal-quantity-' + this.id)
    m ? m.remove() : ''
  },
  data() {
    return {
      quantity: this.value.quantity || 0,
      id: this.value.id || '',
      float: this.value.float || false,
      remove: false
    }
  },
  watch: {
    quantity(quantity) {
      if (quantity) {
        this.$store.dispatch('updateProductQuantity', {
          productId: this.id,
          quantity: this.quantity
        })
      }
    }
  },
  methods: {
    removeProduct() {
      this.$store.dispatch('removeProductFromCart', this.id).then(() => {
        modal(document.getElementById('modal-quantity-' + this.id)).hide()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './assets/theme.scss';

button.uk-button {
  text-transform: none;
}
.reduce,
.add {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $global-primary-color;
  margin: 0 1em;
  color: white;
}
</style>
