<template>
  <div class="uk-container" v-if="loading">
    Ładowanie zamówienia
  </div>
  <div class="uk-container" v-else-if="order">
    <h3 class="uk-text-primary">{{ retailerName }}</h3>
    <product-list v-model="order"></product-list>
    <div class="uk-text-center">
      <button class="payment" uk-toggle="target: #payment-details">
        Dane do płatności
      </button>
    </div>
    <div class="uk-margin-large-top">
      <span class="uk-text-primary">Link do zamówienia:</span><br />
      <div class="uk-grid-small" uk-grid>
        <div class=" uk-width-2-3">
          <input
            type="text"
            id="orderLink"
            class="uk-input uk-width-1-1"
            :value="url"
          />
        </div>
        <div class=" uk-width-1-3">
          <button class="copy-link uk-width-1-1" @click="copyLink">
            {{ linkCopied ? 'Skopiowano' : 'Kopiuj' }}
          </button>
        </div>
      </div>
    </div>
    <payment-details :actionId="order.actionId"></payment-details>
  </div>
  <div v-else class="uk-container">
    <h4 class="uk-text-primary">Zamówienie nie istnieje</h4>
    <div class="uk-text-justify">
      Sprawdź poprawność adresu w przeglądarce lub przejdź do
      <router-link :to="{ name: 'home' }">strony głównej</router-link>.
    </div>
  </div>
</template>

<script>
import ProductList from '../components/Cart/ProductList'
import PaymentDetails from '../components/Cart/PaymentDetails'

import { mapGetters } from 'vuex'

export default {
  name: 'Order',
  computed: {
    ...mapGetters(['retailerNameByActionId']),
    url() {
      return document.location.href
    }
  },
  components: {
    ProductList,
    PaymentDetails
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.$store.dispatch('orderById', this.id).then(order => {
      this.loading = false
      this.order = order
      if (order) {
        this.retailerName = this.retailerNameByActionId(this.order.actionId)
      }
    })
  },
  data() {
    return {
      retailerName: '',
      order: null,
      loading: true,
      linkCopied: false
    }
  },
  methods: {
    subTotal(product) {
      return parseFloat(product.quantity * product.price).toFixed(2)
    },
    copyLink() {
      document.getElementById('orderLink').select()
      document.execCommand('copy')
      this.linkCopied = true
      setTimeout(() => {
        this.linkCopied = false
      }, 5000)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './assets/theme.scss';

button {
  @extend .uk-button;
  @extend .uk-border-rounded;
  text-transform: none;
  &.payment {
    @extend .uk-button-primary;
  }
  &.copy-link {
    @extend .uk-button-secondary;
    @extend .uk-padding-remove;
  }
}
</style>
