<template>
  <div id="payment-details" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical wrapper">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h3 class="uk-text-primary uk-text-center">
        Dane do płatności
      </h3>
      <div class="card uk-margin-bottom"></div>
      <div v-if="type == 'account'">
        <div class="uk-margin-small-bottom">
          <b>Rodzaj:</b><br />przelew {{ prepaid ? '(przedpłata)' : '' }}
        </div>
        <div class="uk-margin-small-bottom">
          <b>Konto:</b><br />
          <span v-html="details"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentDetails',
  props: ['actionId'],
  data() {
    return {
      type: null,
      details: null,
      prepaid: true
    }
  },
  computed: {
    ...mapGetters(['getPaymentDetailsForAction'])
  },
  created() {
    const payment = this.getPaymentDetailsForAction(this.actionId)

    if (payment) {
      this.type = payment.type
      this.details = payment.details
      this.prepaid = payment.prepaid
    }

    const modal = document.getElementById('payment-details')
    if (modal) {
      modal.remove()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';
.wrapper {
  @extend .uk-modal-body;
}
.card {
  background-image: url('/img/cart/card.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
}
</style>
